export const TwoLeftFeet = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/MTLF/2-left-feet-c.webp"
export const TwoLeftFeetTitle = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/MTLF/2lf-title.webp"
export const CoverFly = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/MTLF/coverfly.webp"
export const Opener = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/MTLF/opener.webp"
export const ThatWasAwesome = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/MTLF/that-was-awesome.webp"
export const TheDancer = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/MTLF/the-dancer.webp"
export const BoomBox = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/MTLF/boombox.webp"
export const UhOh = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/MTLF/uh-oh-b.webp"

export const CokeSpec = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/works/10.A-Coca-Cola-Wedding-(:60-Spec).webp"
export const IFOR = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/works/11.An-Inertial-Frame-of-Reference-(3-Poems).webp"
export const TwoLeftFeetPoster = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/works/12.My-Two-Left-Feet.webp"


export const TMNT = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/resume/3.Teenage-Mutant-Ninja-Turtles.webp"
export const TMNT_OUT_THE_SHADOWS = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/resume/6.Teenage-Mutant-Ninja-Turtles-Out-Of-The-Shadows.webp"
export const PENNY_DREADFUL = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/resume/4.Penny-Dreadful.webp"
export const FINEST_HOURS = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/resume/5.The-Finest-Hours.webp"
export const CLOVER_FIELD = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/resume/7.The-Cloverfield-Paradox.webp"
export const VENOM = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/resume/8.Venom.webp"
export const GRAN_TURISMO = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/resume/9.Gran-Turismo.webp"


export const InstagramURL= "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/socials/1.instagram.webp"
export const LinkedInURL= "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/socials/2.linkedin.webp"
export const THE_KID = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/thekidhimself/damian-2-removebg-preview%20(3).webp"


export const MTLF_FIGHTING_SPIRIT_LAUREL = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/awards/MTLF/1.Fighting_Spirit_Film_Festival_2023_Official_Selection"
export const MTLF_SCREEN_CRAFT_LAUREL = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/awards/MTLF/2.screenscraft_laurel.webp"
export const MTLF_UW_23_LAUREL = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/awards/MTLF/3.UW-23-Official-Selection_white_high_res.webp"
export const MTLF_RED_LIST_LAUREL = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/awards/MTLF/4.MTLF-transformed.webp"

export const MTLF_LOOKBOOK_COVER = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/bg-assets/MTLF-LOOKBOOK-COVER.webp"
export const MTLF_NO_WORDS = "https://txnhbodhypyloqizabkp.supabase.co/storage/v1/object/public/website/assets/bg-assets/MTLF-NOWORDS.webp"
