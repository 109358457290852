import React from 'react';
import { TwoLeftFeet, TwoLeftFeetTitle, CoverFly, BoomBox,ThatWasAwesome,Opener,TheDancer,UhOh } from '../Constants/PictureContstants';

import Nav from './Nav';

const MTLF = () => {
// git
const renderImageAndParagraphElements = () => {
    return (
        <>
            <img src={TwoLeftFeetTitle} alt="Film Title" className="w-full max-w-lg my-10 border-none"/>
            <div className="text-center w-full sm:w-1/3 space-y-8">
                 <p className="mb-2.5 leading-6">Based on the ScreenCraft Short Film Competition Quarterfinalist ranked screenplay!</p>
                 <p className="mb-2.5 leading-6 xl:px-32 md:px-10"><strong>A dancer renting rehearsal space at a failing martial arts academy struggles to make a move on his crush, the caretaker of the dojo; until an outside threat forces them into a romantic duet.</strong></p>
                <p><span className='italic'>My Two Left Feet</span> is a short film in which <span className='italic underline'>Step Up</span> meets <span className='italic underline'>Rumble in the Bronx</span>.<br/>Our team calls it a Rom-Kung Fu!</p>
                <ul>
                    <p className='underline'>Starring</p>
                    <li><span className='font-bold'>Gemma Nguyen</span><br className='sm:hidden'/><span className='italic'>(Everything Everywhere All at Once)</span></li>
                    <li><span className='font-bold'>Harkin Smith</span><br className='sm:hidden'/><span className='italic'>(TNT, Making Moves)</span></li>
                    <li><span className='font-bold'>Charlit Dae</span><br className='sm:hidden'/><span className='italic'>(The Gray Man)</span></li>
                </ul>
            </div>
        </>
    );
};

const renderAdditionalImages = () => {
    // Replace with actual image names and types
    const images = [
        CoverFly,
        BoomBox,
        ThatWasAwesome,
        Opener,
        TheDancer,
        TwoLeftFeet,
        UhOh,
    ];

    return images.map((image, index) => (
        <img key={index} src={image} alt="Film Image" className="w-full max-w-lg my-5" />
    ));
};

const renderSocialMediaLinks = () => {
    return (
        <div className="flex justify-center my-5">
            <a href="https://www.instagram.com/my2leftfeet_movie/" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:text-blue-800">
                Follow our film's festival journey via Instagram
            </a>
        </div>
    );
};
    return (
        <>
            <Nav/>
            <div className="bg-white text-gray-800 font-sans text-base leading-6 antialiased mx-auto h-full w-full">
                <div className="p-5">
                    <div className="my-5">
                        <div className="py-5">
                            <div className="mx-auto">
                                <div className="flex flex-col items-center justify-center">
                                    {renderImageAndParagraphElements()}
                                    <h2 className="text-center text-xl font-bold my-10 text-black font-serif leading-snug">
                                        Our script is ranked in the top #20 on Coverfly's RED LIST for 2023!
                                    </h2>
                                    {renderAdditionalImages()}
                                    {renderSocialMediaLinks()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default MTLF;
