import React from 'react'

const Nav = () => {
    
    const title = "Damian the Director".toUpperCase()
    const navChoices = [
      "Home",
      "My Two Left Feet",
      // "Work",
      "About",
      "Contact",
    ]

  return (
    <div className='sm:h-20 z-10 fixed top-0 left-0 right-0 w-full flex sm:flex-row flex-col bg-black text-white px-6 items-center'>
  <div className='sm:w-1/2 w-full sm:text-2xl text-sm sm:text-left text-center pt-2'>
    <h1 className='sm:mx-6'><a href={"/"}>{title}</a></h1>
  </div>
  <div className='sm:w-1/2 w-full sm:text-xl text-sm sm:py-0 py-2'>
    <ul className='flex flex-row items-center'>
      {navChoices.map((choice)=>{        
        if(choice.toLowerCase() == "my two left feet"){
            return (
                <li className='w-1/5 text-center'><a href={`/${choice.toLowerCase().split(" ").join("-")}`}>{choice}</a></li>
            )
        }
        return(          
          <li className='w-1/4 text-center'><a href={(window.location.pathname == "/") ? `#${choice.toLowerCase()}`:`/#${choice.toLowerCase()}` }>{choice}</a></li>
        )
      })
      }            
    </ul>
  </div>
  </div>
  )
}

export default Nav