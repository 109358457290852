import React, { useState } from 'react';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
function CarouselMobile({currentSlide, setCurrentSlide, movieMetadata }) {
    

    function nextSlide() {
        setCurrentSlide((oldSlide) => {
            let next = oldSlide + 1;
            if (next >= movieMetadata.length) next = 0;
            return next;
        });
    }

    function prevSlide() {
        setCurrentSlide((oldSlide) => {
            let prev = oldSlide - 1;
            if (prev < 0) prev = movieMetadata.length - 1;
            return prev;
        });
    }

    return (
        <div className="flex flex-col w-full ">
            <div className='flex lg:flex-row flex-nowrap flex-col justify-center mx-auto lg:w-1/2 w-full space-x-0 min-h-[350px]'>
            

            

            <div className='flex flex-row justify-center lg:w-1/2 w-full'>
                <img 
                    src={movieMetadata[currentSlide].imageSrc} 
                    alt={`Slide ${currentSlide}`} 
                    className="max-h-[350px] max-w-[350px]"
                />
            </div>
            <div className='mx-auto'>
            <button 
                onClick={prevSlide} 
                className="p-2 hover:bg-gray-300 w-1/2">                
                <NavigateBeforeIcon/>
            </button>
            <button 
                onClick={nextSlide} 
                className=" p-2 hover:bg-gray-300 w-1/2"
            >
                <NavigateNextIcon/>
            </button>
            </div>
            
            </div>

            {/* Picture Meta  */}
            <div className="flex flex-col lg:w-1/4 w-full px-10 py-4 mx-auto min-h-[290px]" >            
                <h2 className="text-xl font-bold mb-1">{movieMetadata[currentSlide].title}</h2>
                <h3 className="text-xl mb-6">{movieMetadata[currentSlide].role}</h3>
                {/* {movieMetadata[currentSlide].description && <p className="text-md leading-6 mt-6">Description:</p>} */}
                {movieMetadata[currentSlide].description && <p className="text-md leading-6 mb-4">{movieMetadata[currentSlide].description}</p>}
                {movieMetadata[currentSlide].awards && <p className="text-md leading-6 text-center mb-4">[Awards Go Here]</p>}                
                {movieMetadata[currentSlide].cta && <button className='py-2 mb-4 rounded-3xl bg-rose-700 text-white lg:w-full w-1/2 mx-auto'>{movieMetadata[currentSlide].cta}</button>}
            </div>
            
        </div>
        
    );
}

export default CarouselMobile;