import React, { useEffect, useState } from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FilloutStandardEmbed } from "@fillout/react";
import { InstagramURL, LinkedInURL } from '../Constants/PictureContstants';

const Contact = ({messageField}) => {

const [contactMethod, setContactMethod] = useState("form")
const socialsImages = [InstagramURL, LinkedInURL]
const contact_icons = [
    <EditIcon sx={{ color: 'white' }}/>,
    <InstagramIcon sx={{ color: 'white' }}/>,
    <LinkedInIcon sx={{ color: 'white' }}/>,
    // <EmailIcon sx={{ color: 'white' }}/>,          
  ]

  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://server.fillout.com/embed/v1/";
    script.async = true;
    document.body.appendChild(script);

    // Cleanup on component unmount
    return () => {
        document.body.removeChild(script);
    };
}, [contactMethod, messageField]); 
  return (
    <div id='contact' className='flex flex-col w-full min-h-[500px]'>
    {/* Contact-Type Selection */}
    <h2 className='text-white text-center text-2xl my-3 font-bold'>Contact</h2>        
    
    <div className='flex flex-row mx-auto lg:w-1/3 w-full justify-center'>
      {contact_icons.map((icon, ind)=>{
        let classAddendum = ""
        if(ind == 0 ) classAddendum= " border-b-4 border-pink-500"          
        return(
          <div className='flex w-1/4 justify-center' >
            <button className={'contact-buttons' + classAddendum} onClick={e=>{
              if(ind == 0){
                setContactMethod("form")
                let doc =document.querySelectorAll(".contact-buttons")
                for(let e of doc){
                  e.classList.remove("border-b-4")
                  e.classList.remove("border-pink-500")                        
                }
                doc[0].classList.add("border-pink-500")
                doc[0].classList.add("border-b-4")
              }else if(ind == 1){
                setContactMethod("instagram")                    
                let doc =document.querySelectorAll(".contact-buttons")
                for(let e of doc){
                  e.classList.remove("border-b-4")
                  e.classList.remove("border-pink-500")                        
                }
                doc[1].classList.add("border-pink-500")
                doc[1].classList.add("border-b-4")
              }else if(ind == 2){
                setContactMethod("linkedin")                    
                let doc =document.querySelectorAll(".contact-buttons")
                for(let e of doc){
                  e.classList.remove("border-b-4")
                  e.classList.remove("border-pink-500")                        
                }
                doc[2].classList.add("border-pink-500")
                doc[2].classList.add("border-b-4")
              }
            }}>
              {icon}
            </button>
          </div>
        )
      })}          
    </div>
    
    <div className='flex flex-col relative justify-center lg:w-1/2 mx-auto w-4/5 my-6'>
    {(contactMethod=="form" && messageField) &&           
      <>
        
        <div 
            style={{ width: '100%', height: '500px' }}
            data-fillout-id={"mM5KayMwkPus?message="+messageField}
            data-fillout-embed-type="standard"
            data-fillout-inherit-parameters
            data-fillout-dynamic-resize
        ></div>
        <div className='absolute bg-black w-full h-8 md:h-10 lg:h-10 xl:h-10 2xl:h-12 3xl:h-16 bottom-2'></div>
      </>
      
    }
    {(contactMethod=="form" && !messageField) &&           
      <>
        
        <div 
            style={{ width: '100%', height: '500px' }}
            data-fillout-id={"mM5KayMwkPus"}
            data-fillout-embed-type="standard"
            data-fillout-inherit-parameters
            data-fillout-dynamic-resize
        ></div>
        <div className='absolute bg-black w-full h-8 md:h-10 lg:h-10 xl:h-10 2xl:h-12 3xl:h-16 bottom-2'></div>
      </>
      
    }
    {contactMethod == "instagram" &&         
      <div className="mx-auto max-w-[600px]"><a target='_blank' href="https://instagram.com/sincerely_damian"><img className={"rounded-3xl"} src={socialsImages[0]}></img></a></div>
    }
    {contactMethod == "linkedin" &&         
      <div className="mx-auto max-w-[600px]"><a target='_blank' href="https://www.linkedin.com/in/damian-gonzalez-7b705169/"><img className={"rounded-3xl"} src={socialsImages[1]}></img></a></div>          
    }
    </div>
  
  </div>
  )
}

export default Contact