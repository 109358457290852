import React, { useEffect, useState } from 'react';
import Nav from './Nav';
import Contact from './Contact';
import ContactScreener from './ContactScreener';
import { MTLF_NO_WORDS, TwoLeftFeetPoster } from '../Constants/PictureContstants';
const Screener = ({ videoSource, videoType, title }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [passwordInput, setPasswordInput] = useState('');
      // State for window width and height
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to handle update of states
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])
    const handlePasswordSubmit = () => {
        if (passwordInput.toUpperCase() === 'UW23') {
            setIsAuthenticated(true);
        } else {
            alert('Wrong password. Try again. (See invitation card for password)');
            setPasswordInput('');
        }
    };

   function notAuthenticated(){
        return (

            <div className="flex flex-col items-center justify-center">
                <div className="flex w-full sm:pb-0 pb-20">
                    <Nav/>
                </div>
                <div className='bg-opacity-50 bg-yellow-600 mb-4 '>
                    {windowSize.width <500 ? 
                        <>
                        <p className="mx-4 text-3xl font-bold">Exclusive Screening of </p>
                        <p className="mx-4 text-3xl font-bold text-center">"{title}"</p>
                        </>
                    :
                        <p className="mx-4 text-3xl font-bold">Exclusive Screening of "{title}"</p>
                    }
                </div>
                <div className="flex w-full ">
                    <img className='max-w-[300px] mx-auto' src={TwoLeftFeetPoster}></img>
                </div>
                <div className="mb-4 text-2xl font-bold">Enter Password</div>
                <input
                    type="text"
                    value={passwordInput}
                    onChange={e => setPasswordInput(e.target.value)}
                    className="mb-4 p-2 border rounded-lg w-1/4 text-center"
                    placeholder="****"
                />                
                <button onClick={handlePasswordSubmit} className="w-1/2 p-2 bg-pink-500 text-white rounded-2xl">WATCH NOW</button>
            </div>
        );
    }

    return (
        <div className={`flex flex-col items-center justify-center min-h-screen p-4 bg-[url('https://yqijlrrazmrxzbznyqds.supabase.co/storage/v1/object/sign/web-assets/BG-ASSETS/MTLF-NOWORDS.webp?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1cmwiOiJ3ZWItYXNzZXRzL0JHLUFTU0VUUy9NVExGLU5PV09SRFMud2VicCIsImlhdCI6MTcwMDQzMjg3OSwiZXhwIjoyMDE1NzkyODc5fQ.JJ687ANehcUHlaUc61MBJJ6Hb9azrGU8VNWVP-JU3MQ&t=2023-11-19T22%3A27%3A59.126Z')] bg-cover`}>
            {!isAuthenticated ? 
                notAuthenticated()
            :
            <>
            <h2 className=''>Damian The Director presents:</h2>
            <div className="flex flex-col h-fit w-full max-w-2xl">
                <h1 className="bg-opacity-50 bg-black text-yellow-300 mb-8 text-2xl font-bold text-center">{title}</h1>
                {videoType === 'youtube' ? (
                    <iframe 
                        width="100%" 
                        height="315" 
                        src={`https://www.youtube.com/embed/${videoSource}?mode=opaque&amp;rel=0&amp;autohide=1&amp;showinfo=0&amp;wmode=transparent`} 
                        title={title}                     
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                        rel
                        className="border-none rounded-lg shadow-md max-w-full"
                    />
                ) : (
                    <video 
                        onContextMenu={(e) => e.preventDefault()} 
                        controls 
                        width="100%" 
                        height="315" 
                        className="rounded-lg shadow-md max-w-full"
                    >
                        <source src={videoSource} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
            </div>
            {/* <div className="mt-8 p-4 border rounded-lg bg-gray-100 text-sm text-gray-700 w-full max-w-2xl">
                <h2 className="font-bold mb-2">Legal Notice:</h2>
                <p>
                    Unauthorized downloading, distribution, or recording of this video is strictly prohibited. Any violations may be subject to legal action. By continuing to view this video, you acknowledge and agree to these terms.
                </p>
            </div> */}            
            <div className="mt-8 p-4 w-full flex flex-row mx-auto min-h-[500px]">
                {/* <button>HOME</button> */}
                <ContactScreener/>
            </div>
            </>
            }
        </div>
    );
}

export default Screener;



// <Screener videoSource="VIDEO_ID" videoType="youtube" title={"My Two Left Feet"} />

// <Screener videoSource="VIDEO_ID" videoType="youtube" title={"My Two Left Feet"} />