import React, { useState } from 'react'
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { FilloutStandardEmbed } from "@fillout/react";
import { InstagramURL, LinkedInURL } from '../Constants/PictureContstants';

const ContactScreener = () => {

const [contactMethod, setContactMethod] = useState("form")


const contact_icons = [
    <EditIcon fontSize="large" sx={{ color: 'black' }}/>,
    <InstagramIcon fontSize="large" sx={{ color: 'black' }}/>,
    <LinkedInIcon fontSize="large" sx={{ color: 'black' }}/>,
    // <EmailIcon sx={{ color: 'white' }}/>,          
  ]

const [showContacts, setShowContacts] = useState(false)
  return (
    <div id='contact' className='flex flex-col w-full min-h-[500px]'>
    {/* Contact-Type Selection */}
    <div className='flex flex-row justify-center space-x-10'>
      <button onClick={(e)=>window.location.href="/"} className='bg-black opacity-50 rounded-3xl cursor-pointer sm:w-1/4 lg:w-1/6 w-1/2 p-2 text-white text-center text-lg my-3'>HOME</button>        
      <button onClick={(e)=>setShowContacts(!showContacts)} className='bg-black opacity-50 rounded-3xl cursor-pointer sm:w-1/4 w-1/2 lg:w-1/6 lg:p-2 p-1 text-white text-center text-lg my-3 '>{(showContacts ? " HIDE" : "SHOW")} CONTACTS</button>              
    </div>
    {showContacts &&     
    <>
    <div className='flex flex-row mx-auto lg:w-1/3 w-full justify-center'>
      {contact_icons.map((icon, ind)=>{
        let classAddendum = ""
        if(ind == 0 && contactMethod == "form" ) classAddendum= " border-b-4 border-pink-500"          
        if(ind == 1 && contactMethod == "instagram" ) classAddendum= " border-b-4 border-pink-500"          
        if(ind == 2 && contactMethod == "linkedin" ) classAddendum= " border-b-4 border-pink-500"          
        return(
          <div className='flex w-1/4 justify-center' >
            <button className={'contact-buttons' + classAddendum} onClick={e=>{
              if(ind == 0){
                setContactMethod("form")
                let doc =document.querySelectorAll(".contact-buttons")
                for(let e of doc){
                  e.classList.remove("border-b-4")
                  e.classList.remove("border-pink-500")                        
                }
                doc[0].classList.add("border-pink-500")
                doc[0].classList.add("border-b-4")
              }else if(ind == 1){
                setContactMethod("instagram")                    
                let doc =document.querySelectorAll(".contact-buttons")
                for(let e of doc){
                  e.classList.remove("border-b-4")
                  e.classList.remove("border-pink-500")                        
                }
                doc[1].classList.add("border-pink-500")
                doc[1].classList.add("border-b-4")
              }else if(ind == 2){
                setContactMethod("linkedin")                    
                let doc =document.querySelectorAll(".contact-buttons")
                for(let e of doc){
                  e.classList.remove("border-b-4")
                  e.classList.remove("border-pink-500")                        
                }
                doc[2].classList.add("border-pink-500")
                doc[2].classList.add("border-b-4")
              }
            }}>
              {icon}
            </button>
          </div>
        )
      })}          
    </div>      
        
    <div className='flex flex-col relative justify-center lg:w-1/2 mx-auto w-4/5 my-6'>
    {contactMethod=="form" &&           
      <>
        <FilloutStandardEmbed filloutId="mM5KayMwkPus" dynamicResize={true} />            
        <div className='absolute bg-black w-full h-8 md:h-10 lg:h-10 xl:h-10 2xl:h-12 3xl:h-16 bottom-2'></div>
      </>
      
    }
    {contactMethod == "instagram" &&         
      <div className="mx-auto max-w-[600px]"><a target='_blank' href="https://instagram.com/sincerely_damian"><img className={"rounded-3xl"} src={InstagramURL}></img></a></div>

    }
    {contactMethod == "linkedin" &&         
      <div className="mx-auto max-w-[600px]"><a target='_blank' href="https://www.linkedin.com/in/damian-gonzalez-7b705169/"><img className={"rounded-3xl"} src={LinkedInURL}></img></a></div>          
    }
    </div>
    </>
    }
  </div>
  )
}

export default ContactScreener