
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './Components/HomePage';
import Screener from './Components/Screener';
import { initGA, logPageView } from './Components/Analytics';
import{useEffect} from 'react'
import UnderConstruction from './Components/UnderConstruction';
import MTLF from './Components/MTLF';
// import award1 from 
function App() {

  
    useEffect(() => {
      initGA('G-0YXBWHSWCYG-0YXBWHSWCY'); // Replace with your Google Analytics tracking ID
      logPageView();
    }, []);

    const location = useLocation();

    useEffect(() => {
      logPageView(); // Log the initial pageview      
    }, [location]);
  return (

      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route exact path="/my-two-left-feet" element={<MTLF/>} />
        <Route path="/my-two-left-feet/screener" element={<Screener videoSource={"kJxCYce15dA"} videoType={"youtube"} title={"My Two Left Feet"}/>} />
    
      </Routes>    
  );
}

export default App;
