import React from 'react';

function Carousel({ currentSlide, setCurrentSlide, movieMetadata }) {

    function nextSlide() {
        setCurrentSlide((oldSlide) => (oldSlide + 1) % movieMetadata.length);
    }

    function prevSlide() {
        setCurrentSlide((oldSlide) => (oldSlide - 1 + movieMetadata.length) % movieMetadata.length);
    }

    function getPrevSlide() {
        return (currentSlide - 1 + movieMetadata.length) % movieMetadata.length;
    }

    function getNextSlide() {
        return (currentSlide + 1) % movieMetadata.length;
    }

    function renderMetaData(slideIndex) {
        return (
            <div className="flex flex-col lg:w-1/3 w-full px-2 py-2">
                <h2 className="text-lg font-bold mb-1">{movieMetadata[slideIndex].title}</h2>
                <h3 className="text-lg mb-3">{movieMetadata[slideIndex].role}</h3>
                {movieMetadata[slideIndex].description && <p className="text-sm leading-6 mb-2">{movieMetadata[slideIndex].description}</p>}
                {movieMetadata[slideIndex].awards && <p className="text-sm leading-6 text-center mb-2">[Awards Go Here]</p>}
                {movieMetadata[slideIndex].cta && <button className='py-1 mb-2 rounded-3xl bg-rose-700 text-white lg:w-full w-3/4 mx-auto'>{movieMetadata[slideIndex].cta}</button>}
            </div>
        );
    }

    function renderMetaDataMobile(slideIndex) {
        return (
            <div className="flex flex-col w-full lg:w-1/3 px-2 py-2">
                <h2 className="text-lg font-bold mb-1">{movieMetadata[slideIndex].title}</h2>
                <h3 className="text-lg mb-3">{movieMetadata[slideIndex].role}</h3>
                {movieMetadata[slideIndex].description && <p className="text-sm leading-6 mb-2">{movieMetadata[slideIndex].description}</p>}
                {movieMetadata[slideIndex].awards && <p className="text-sm leading-6 text-center mb-2">[Awards Go Here]</p>}
                {movieMetadata[slideIndex].cta && <button className='py-1 mb-2 rounded-3xl bg-rose-700 text-white lg:w-full w-3/4 mx-auto'>{movieMetadata[slideIndex].cta}</button>}
            </div>
        );
    }
    if(window.screen.width < 500)
    return (
        <div className="flex flex-col w-full">
            {/* Horizontal scrollable container */}
            <div className="flex flex-row lg:justify-center w-full overflow-x-auto scroll-smooth snap-x snap-mandatory">
                {/* Slide container */}
                {movieMetadata.map((data, index) => (
                    <div key={data.title} className="flex flex-col snap-center shrink-0 justify-center items-center w-full" style={{ minWidth: "100%" }}>
                        <img
                            src={data.imageSrc}
                            alt={`Slide ${index}`}
                            className="w-full max-w-[350px] h-auto object-contain"
                        />
                        {renderMetaDataMobile(index)}
                    </div>
                ))}
            </div>
        </div>
    );


    return (
        <div className="flex flex-col w-full">
            <div className='flex lg:flex-row flex-nowrap flex-col justify-center mx-auto lg:w-3/4 w-full space-x-0 min-h-[350px]'>
        
                {/* Previous slide image */}
                <div className='flex flex-row justify-center lg:w-1/3 w-full opacity-50 cursor-pointer' onClick={prevSlide}>
                    <img
                        src={movieMetadata[getPrevSlide()].imageSrc}
                        alt={`Slide ${getPrevSlide()}`}
                        className="max-h-[350px] max-w-[350px] transform scale-75 hover:border-slate-300 hover:border-4"
                    />
                </div>

                {/* Current slide image */}
                <div className='flex flex-row justify-center lg:w-1/3 w-full'>
                    <img
                        src={movieMetadata[currentSlide].imageSrc}
                        alt={`Slide ${currentSlide}`}
                        className="max-h-[350px] max-w-[350px]"
                    />
                </div>

                {/* Next slide image */}
                <div className='flex flex-row justify-center lg:w-1/3 w-full opacity-50 cursor-pointer' onClick={nextSlide}>
                    <img
                        src={movieMetadata[getNextSlide()].imageSrc}
                        alt={`Slide ${getNextSlide()}`}
                        className="max-h-[350px] max-w-[350px] transform scale-75 hover:border-slate-300 hover:border-4"
                    />
                </div>
            </div>

            {/* Picture Meta */}
            <div className="flex lg:flex-row flex-nowrap flex-col justify-center mx-auto lg:w-3/4 w-full space-x-16">
                {renderMetaData(getPrevSlide())}
                {renderMetaData(currentSlide)}
                {renderMetaData(getNextSlide())}
            </div>
        </div>
    );
}

export default Carousel;
