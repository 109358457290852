import React from 'react'

import { useEffect, useRef, useState } from 'react';
import Carousel from './Carousel';
import Contact from './Contact';
import Nav from './Nav';
import CarouselMobile from './CarouselMobile';
import { THE_KID, CLOVER_FIELD, CokeSpec, FINEST_HOURS, GRAN_TURISMO, IFOR, PENNY_DREADFUL, TMNT, TMNT_OUT_THE_SHADOWS, TwoLeftFeetPoster, UhOh, VENOM, MTLF_FIGHTING_SPIRIT_LAUREL, MTLF_SCREEN_CRAFT_LAUREL, MTLF_UW_23_LAUREL, MTLF_RED_LIST_LAUREL } from '../Constants/PictureContstants';

const HomePage = () => {    
    
    

    const images = [
      {name: "MTLF_FIGHTING_SPIRIT_LAUREL", url: MTLF_FIGHTING_SPIRIT_LAUREL},
      {name: "MTLF_SCREEN_CRAFT_LAUREL", url: MTLF_SCREEN_CRAFT_LAUREL},
      {name: "MTLF_UW_23_LAUREL", url: MTLF_UW_23_LAUREL},
      {name: "MTLF_RED_LIST_LAUREL", url: MTLF_RED_LIST_LAUREL},
    ]
    
    let worksImages =  [
        {name: "My Two Left Feet", url:TwoLeftFeetPoster},
        {name:"An Inertial Frame of Reference (3 Poems)", url:IFOR},
        {name:"A Coca Cola Wedding (:60 Spec)", url:CokeSpec},
      ]
    let resumeImages = [
      { name: "Gran Turismo", url: GRAN_TURISMO },
      { name: "Venom", url: VENOM },
      { name: "The Cloverfield Paradox", url:  CLOVER_FIELD},
      { name: "Teenage Mutant Ninja Turtles Out Of The Shadows", url: TMNT_OUT_THE_SHADOWS},
      { name: "The Finest Hours", url:  FINEST_HOURS  },
      { name: "Penny Dreadful", url:  PENNY_DREADFUL},
      { name: "Teenage Mutant Ninja Turtles", url: TMNT },
    ]
    let resumeImagesMeta = [    
      {
        description:"DEMO-1",
        role:"DEMO-1",
      },
      {
        description:"DEMO-2",
        role:"DEMO-2",
      },
      {
        description:"DEMO-3",
        role:"DEMO-3",
      },
      {
        description:"DEMO-4",
        role:"DEMO-4",
      },
      {
        // description:"When a kingpin threatens New York City, a group of mutated turtle warriors must emerge from the shadows to protect their home.",
        role:"VFX Production Assistant",
      },
      {
        // description:"Explorer Sir Malcolm Murray, American gunslinger Ethan Chandler, scientist Victor Frankenstein and medium Vanessa Ives unite to combat supernatural threats in Victorian London.",
        role:"VFX Production Assistant",
      },
      {
        // description:"The Coast Guard makes a daring rescue attempt off the coast of Cape Cod after a pair of oil tankers are destroyed during a blizzard in 1952.",
        role:"VFX Production Assistant",
      },
      {
        // description:"The Turtles get into another battle with their enemy the Shredder, who has acquired new allies: the mutant thugs Bebop and Rocksteady and the alien being Krang.",
        role:"VFX Coordinator",
      },
      {
        // description:"Orbiting a planet on the brink of war, scientists test a device to solve an energy crisis, and end up face-to-face with a dark alternate reality.",
        role:"VFX Coordinator",
      },
      {
        // description:"A failed reporter is bonded to an alien entity, one of many symbiotes who have invaded Earth. But the being takes a liking to Earth and decides to protect it.",
        role:"Production Assistant",
      },
      {
        // description:"Based on the unbelievable, inspiring true story of a team of underdogs - a struggling, working-class gamer, a failed former race car driver, and an idealistic motorsport exec - who risk it all to take on the most elite sport in the world.",
        role:"VFX Coordinator",
      },
    ]
    let worksImagesMeta = [    
      {
        description:"DEMO-1",
        role:"DEMO-1",
      },
      {
        description:"DEMO-2",
        role:"DEMO-2",
      },
      {
        description:"DEMO-3",
        role:"DEMO-3",
      },
      {
        description:"DEMO-4",
        role:"DEMO-4",
      },
      {
        title:"A Coca-Cola Wedding (Spec)",
        description: `A celebration of love, diversity, and unity; and Coca-Cola is invited to the reception.`,
        role:"Producer, Co-Director, Editor",
        cta:"",
        awards: "",
      },
      {
        title: "Inertial Frame Of Reference",
        description: `Discover how our dreams reinterpret memories long after relationships have faded.
          This award-winning, critically acclaimed visual journey tells a story of love and separation.`,
        role:"Writer, Co-Director",
        cta:"",
        awards: "",
      },
      {
        title: "My Two Left Feet",
        description: `Despite their mutual crush, a dancer renting rehearsal space at a failing martial arts school and the caretaker of the Dojo struggle to break the ice. That is until a local thug threatens them, and they must combine their talents to defend each other.`,
        role:"Writer, Producer, Director",
        cta:"Request Screening",
        awards: "",
      },
    ]
    
    
    
    
    

  
    // const user_biography = `Bronx-born, Los Angeles-cultivated writer/producer/director. He's honed his film craft while working on set, on summer blockbusters, and as an assistant, studio-side, with some of the most talented creative execs & filmmakers in Hollywood; at Fox Searchlight, Bad Robot, & Netflix. His Romantic Kung-Fu short, "My Two Left Feet." is currently breaking hearts (& necks) on the 23/24 Film Fest circuit.`
    // const user_biography = `Damian Gonzales, known to many as "Damian the Director", is a Bronx-born, Los Angeles-cultivated film director with a unique flair for storytelling. As a sought-after Los Angeles director and New York director, Damian has perfected his filmmaking skills on set, contributing to summer blockbusters, and collaborating studio-side with some of the industry's leading creative minds at renowned institutions like Fox Searchlight, Bad Robot, and Netflix. A notable highlight in Damian's film journey is his captivating Romantic Kung-Fu film titled "My Two Left Feet." This extraordinary Kung fu film is making waves and breaking both hearts and necks as it tours the 23/24 Film Fest circuit. Whether you recognize him as "Damian Directs" or simply "Damian the Director", there's no doubt that Damian Gonzales stands out as a shining talent in the directorial realm.`
    // const user_biography = `Meet Damian Gonzalez – the formidable Los Angeles director shaping the future of cinema.   
    //   As a New York native, Damian has masterfully weaved the cosmopolitan vibrancy of the east with the cinematic brilliance of the west.
    //   "Damian the Director" is the creative genius behind some of the industry's most compelling narratives.    
    //   Having cut his teeth on summer blockbusters, Damian's depth of on-set experience is unparalleled. 
    //   Damian is a film maker, set apart with tenure as an assistant, working elbow-to-elbow with the crème de la crème of Hollywood
    //     at industry titans like Fox Searchlight, Bad Robot, and Netflix. 
    //   It's one thing to be part of the industry, but "Damian the Director" is actively redefining it.
    //   Damian directs films that resonate, such as "My Two Left Feet", coined as the world's first Rom-Kung Fu.
    //   Damian's signature blend of heart-throbbing romance and pulse-racing action not only claims accolades     
    //     on the 23/24 Film Fest circuit, but also sets a new benchmark for what "a Damian movie" stands for.  
    // In your quest for the next big thing in film, whether you're seeking a visionary to helm a project or a groundbreaking narrative that resonates, Damian Gonzalez is your answer.`
    const user_biography =  `Damian Gonzalez aka "Damian the Director" is the formidable Los Angeles director shaping the future of film. As a Nuyorican, Damian weaves the cosmopolitan cool of the East with the cinematic heat of the West. For over a decade, Damian has cut his teeth on blockbusters; working on-set, in-office, and elbow-to-elbow with the crème de la crème of Hollywood creatives at Fox Searchlight, Bad Robot, NBC Universal, and Netflix. It's one thing to be part of the industry, but Damian is actively working to redefine it with his genre-blending films that resonate. Damian's signature approach can be seen in his newest short film, "My Two Left Feet." Coined as a Rom-Kung Fu, it's garnering accolades on the 23/24 Film Fest circuit.`
    const accolades = [
      `Quarterfinalist, WeScreenplay Diverse Voices Lab Fall 2023 with '92
      `,
      `Quarterfinalist, ScreenCraft Sci-Fi & Fantasy Screenwriting Competition 2023 with C IS FOR CYBORG
      `,
      `Quarterfinalist, ScreenCraft Short Film Screenplay Competition 2023 with MY TWO LEFT FEET
      `,
      `Quarterfinalist, WeScreenplay Short Script Lab 2023 with SMARTY PANTS
      `,
      `Quarterfinalist, The Golden Script Competition 2023 with WEAPON`,
    ]
    
    const [currentSlide, setCurrentSlide] = useState(0)
    const [messageField, setMessageField] = useState("")  
    const containerRef = useRef(null);
  

    const HOME_BG = " bg-[#111111]" 
    const HOME_TEXT = " text-white"
    
    const WORKS_BG = " bg-[#333333]"
    const WORKS_TEXT = " text-white"
    
    const CREDITS_BG = " bg-[#555555]"
    const CREDITS_BORDER = " border-[#555555]"
    const CREDITS_TEXT = " text-white"
    
    const ABOUT_BG = " bg-[#777777]"
    const ABOUT_TEXT = " text-white" 
    
    const CONTACT_BG = " bg-black"
    const CONTACT_TEXT = " text-white"
    
    

  
//   let CONTACT_BTN_COLOR = " bg-pink-500"
const CTA_ONE_BG = ' bg-pink-500'
const CTA_ONE_TEXT = ' text-white font-bold'

const CTA_TWO_BG = ' bg-white'
const CTA_TWO_TEXT = ' text-black font-bold'

    useEffect(() => {
      const handleWheel = (e) => {
        if (containerRef.current) {
          // Scrolling using deltaY value from wheel event
          e.preventDefault()
          containerRef.current.scrollLeft += e.deltaY;
        }
      };
  
      // Attach the event listener
      if (containerRef.current) {
        containerRef.current.addEventListener('wheel', handleWheel);
      }
  
      // Clean up
      return () => {
        if (containerRef.current) {
          containerRef.current.removeEventListener('wheel', handleWheel);
        }
      };
    }, []);
  
  
    function MovieCard({ imageSrc, title, description,role, setCurrentSlide, index}) {
      return (
          <div className="flex-none w-64 mr-4">
              <img onClick={(e)=>{setCurrentSlide(index)}} src={imageSrc} alt={title} className="w-full h-48 object-cover mb-2 rounded-lg shadow-lg cursor-pointer"/>
              <h2 className="text-xl font-bold mb-1">{title}</h2>
              <h3 className="text-xl mb-1">{role}</h3>
              {/* <p className="text-sm">{description}</p> */}
          </div>
      );
  }
    function MovieCardGrid({ imageSrc, title, description,role, setCurrentSlide, index}) {
      return (
          <div className={"w-1/3 border-4 " + CREDITS_BORDER + CREDITS_TEXT}>
              <img  src={imageSrc} alt={title} className="w-full h-48 object-cover mb-2 rounded-lg shadow-lg"/>
              <h2 className="text-xl font-bold mb-1">{title}</h2>
              <h3 className="text-xl mb-1">{role}</h3>
              {/* <p className="text-sm">{description}</p> */}
          </div>
      );
  }
  // State for window width and height
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Function to handle update of state
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });      
 
    }

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Remove event listener on cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [])

  function MoviesScrollBox(currentSlide, includeCarousel) {
    // Places latest work first (using nomenclature #.Movie_Name)
    let worksImagesCopy = []
    let worksImagesMetaCopy = []
    if(includeCarousel){
      worksImagesCopy = [...worksImages]
      worksImagesMetaCopy =  [...worksImagesMeta].reverse()
    }else{        
         worksImagesCopy = [...resumeImages]
         worksImagesMetaCopy =  [...resumeImagesMeta].reverse()
    }
  
    let movies = worksImagesCopy.map((workImg,index)=>{
        return({
          imageSrc: workImg.url,
          title: workImg.name,
          role: worksImagesMetaCopy[index].role,
          description: worksImagesMetaCopy[index].description,
        //   cta: worksImagesMetaCopy[index].cta,
          // awards: worksImagesMetaCopy[index].cta,
        })
    })
    
    return (
      <>
        {(includeCarousel && windowSize.width > 1050) && 
        <Carousel currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} movieMetadata={movies}/>
        }
        {(includeCarousel && windowSize.width <= 1050) && 
        <CarouselMobile currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} movieMetadata={movies}/>
        }
        {/* {includeCarousel && <Carousel currentSlide={currentSlide} setCurrentSlide={setCurrentSlide} movieMetadata={movies}/>} */}
        {/* <div className="flex overflow-auto my-4 mx-10 space-x-4 sm:w-1/3 sm:mx-auto" ref={containerRef}>
            {movies.map((movie, index) => {
                if(index != currentSlide)
                  return (
                    <MovieCard 
                        key={index}
                        imageSrc={movie.imageSrc}
                        title={movie.title}
                        description={movie.description}
                        role={movie.role}
                        setCurrentSlide={setCurrentSlide}
                        index={index}
                    />
            )})}
        </div> */}
      </>
    );
  }
  function MoviesGridBox(currentSlide, includeCarousel) {
    // Places latest work first (using nomenclature #.Movie_Name)
    let worksImagesCopy = []
    let worksImagesMetaCopy = []
    if(includeCarousel){
      worksImagesCopy = [...worksImages]
      worksImagesMetaCopy =  [...worksImagesMeta].reverse()
    }else{        
         worksImagesCopy = [...resumeImages]
         worksImagesMetaCopy =  [...resumeImagesMeta].reverse()
    }
  
    let movies = worksImagesCopy.map((workImg,index)=>{
        return({
          imageSrc: workImg.url,
          title: workImg.name,
          role: worksImagesMetaCopy[index].role,
          description: worksImagesMetaCopy[index].description,
          // cta: worksImagesMetaCopy[index].cta,
          // awards: worksImagesMetaCopy[index].cta,
        })
    })
    
    return (      
        <div className="flex flex-row flex-wrap sm:w-3/4 mx-auto gap-y-10">
            {movies.map((movie, index) => {                
                  return (
                    <MovieCardGrid 
                        key={index}
                        imageSrc={movie.imageSrc}
                        title={movie.title}
                        description={movie.description}
                        role={movie.role}
                        setCurrentSlide={setCurrentSlide}
                        index={index}
                    />
            )})}
        </div>      
    );
  }
    

  return (
    <div className='flex flex-col relative w-screen overflow-x-hidden bg-black'>
      {/* Sticky Nav */}
            <Nav/>

      {/* Home */}
      <div id='home' className={'sm:mt-20 mt-24' + HOME_BG + HOME_TEXT}>
        {/* Feature & Awards Banner Container */}
        <div className='relative bg-black flex flex-col items-center sm:max-w-[1200px] sm:max-h-[700px] max-w-[480px] max-h-[500px] mx-auto'>
          {/* Feature Image */}
          <img className='w-full' src={UhOh} alt="My Two Left Feet short film movie teaser."/>

          {/* Awards Banner */}
          <div className='absolute bottom-24 sm:bottom-28 left-0 w-full bg-black bg-opacity-60 flex flex-row flex-nowrap overflow-auto justify-around'>
            {images.map((image, index) => (
              <img className="flex-shrink sm:max-h-28 max-h-16" key={index} src={image.url} alt={`Image ${index}`} />
            ))}
          </div>
        {/* Buttons */}
        <div className='sm:absolute relative bottom-0 left-0 w-full flex flex-row justify-evenly my-4'>          
          <button onClick={(e)=>{
            setMessageField("Hello, I would like to see a screening of 'My Two Left Feet', Thanks!")
            window.location.href="/#contact"
            }} className={'sm:w-1/4 w-2/5 sm:p-4 p-2 rounded-3xl' + CTA_ONE_BG + CTA_ONE_TEXT}>Request Screener</button>          
          <button onClick={e=>{
            setCurrentSlide(0)
            window.location.href="#work"
          }} className={'sm:w-1/4 w-2/5 sm:p-4 p-2 rounded-3xl' + CTA_TWO_BG + CTA_TWO_TEXT}>More Info</button>        
        </div>

        </div>

      </div>
      {/* <div className='bg-black w-full h-6'></div> */}

      {/* Works*/}
      <div id='work' className={'flex flex-col' + WORKS_BG + WORKS_TEXT}>
        <h2 className='text-center text-2xl my-3 font-bold'>WORK</h2>
        {/* Carousel */}
        <div className='flex flex-col'>        
          {MoviesScrollBox(currentSlide, true)}
        </div>
      </div>
      
      {/* CREDIT - Works*/}
      <div id='work' className={'flex flex-col' + CREDITS_BG + CREDITS_TEXT}>
        <h2 className='text-center text-2xl my-3 font-bold'>CREDITS</h2>
        {/* Carousel */}
        <div className='flex flex-col mb-10'>        
          {MoviesGridBox(currentSlide, false)}
        </div>
      </div>

      {/* About */}
      <div id='about' className={'' + ABOUT_BG + ABOUT_TEXT }>
        <h2 className='text-center text-2xl my-3 font-bold'>ABOUT</h2>        
        <div className='flex flex-col justify-center w-full lg:px-28 px-0'>      
          
          <div className='flex lg:flex-row lg:flex-wrap flex-col-reverse justify-center lg:w-3/5 w-1/2 lg:mx-auto mx-6'>      
          {/* Accolades */}
            <div className='flex lg:w-3/5 w-full'>                    
                <ul className='flex flex-col space-y-8'>
                  {accolades.map((v,i)=>{
                    return(
                      <li>
                        <p className='text-lg'>{v.split(",")[0]}</p>                          
                        <p className='text-md'>{v.split(",")[1].split(" with ")[0]}</p>                          
                        <p className='text-md font-bold'>{v.split(" with ")[1]}</p>                          
                      </li>
                    )})                      
                  }
                </ul>            
            </div>
          {/* Biopic */}
            <div className='flex lg:w-2/5 w-1/5 justify-center py-10 mx-auto lg:mx-0 lg:relative absolute right-10'>
              <img className="lg:my-5 lg:max-h-[600px] lg:max-w-[600px] max-h-[300px] max-w-[300px]" src={THE_KID} alt=""/>
            </div>  
          
          </div>  
          {/*  BIOGRAPHY */}
          <div className='flex lg:flex-row xl:flex-wrap justify-center lg:w-3/5 w-full lg:mx-auto sm:my-0 my-10 '>      
              <p className='lg:mx-0 mx-10 leading-6 lg:leading-8'>{user_biography}</p>                                                                          
          </div>
          {/* CTA */}
          <div className='flex justify-center w-full py-6'>
            <button onClick={e=>window.location.href = '#contact'} className={'lg:w-2/5 w-4/5 p-4 rounded-3xl'+CTA_ONE_BG + CTA_ONE_TEXT}>Contact</button>
          </div>                      
        </div>
      </div>
      {/* Contact */}
      <div className={''+ CONTACT_BG + CONTACT_TEXT}>
      <Contact messageField={messageField}/>           
      </div>
    </div>
  )
}

export default HomePage